<template>
    <b-row>
        <b-col md="12">
            <div class="text-right">
                <b-button class="btn btn-sm btn-success mb-2" @click="pdfExport">
                    <i class="fa fa-print"></i>{{ $t('globalTrans.print') }}
                </b-button>
            </div>
            <table class="table table-sm table-bordered" style="width:100%">
                <tr>
                    <th style="vertical-align: middle;width: 5%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</th>
                    <th style="vertical-align: middle;width: 10%" class="text-center" rowspan="2">{{ $t('boro_import.company_details_a') }}</th>
                    <th style="vertical-align: middle;width: 10%" class="text-center" rowspan="2"> {{ $t('boro_import.variety_name') }}</th>
                    <th style="vertical-align: middle" class="text-center" rowspan="2"> {{ $t('boro_import.variety_indetails') }}</th>
                    <th style="vertical-align: middle" class="text-center" colspan="4" > {{ $t('boro_import.future_seed') }} </th>
                    <th style="vertical-align: middle" class="text-center" rowspan="2">{{ $t('boro_import.production_seed_amount') }}</th>
                    <th style="vertical-align: middle" class="text-center" rowspan="2">{{ $t('boro_import.comment') }}</th>
                </tr>
                <tr>
                    <th style="vertical-align: middle; width: 9%"> {{ $t('boro_import.af_1') }}</th>
                    <th style="vertical-align: middle; width: 9%"> {{ $t('boro_import.a_line') }}</th>
                    <th style="vertical-align: middle; width: 9%"> {{ $t('boro_import.r_line') }}</th>
                    <th style="vertical-align: middle; width: 9%"> {{ $t('boro_import.land_amount') }}</th>
                </tr>
                <slot v-for="(item, index) in applicationData">
                    <tr v-for="(itm, indx) in item.add_more_update_1" :key="indx + 10">
                        <slot v-if="indx===0">
                            <td class="text-center" :rowspan="item.add_more_update_1.length">{{ $n(index+1) }}</td>
                            <td :rowspan="item.add_more_update_1.length">
                                {{ local === 'bn' ? (item.name_and_a____7450) : (item.name_and_a____8831)}}{{ ', ' }}
                                {{ local === 'bn' ? item.addressen_5439 : item.addressbn_3295 }}
                                ({{ item.mobile_no__1597 | mobileNumber }}{{ ', ' }}
                                {{ item.email___3403 }})
                            </td>
                        </slot>
                        <td class="text-center" v-for="(dataItem, key) in getMiddleData(itm)" :key="key+200">
                            <span class="">{{ isNaN(dataItem) === false ? $n(dataItem) : dataItem }}</span>
                        </td>
                        <slot v-if="indx===0">
                            <td :rowspan="item.add_more_update_1.length">{{ getEndData(item.add_more_update_1).join(', ') }}</td>
                        </slot>
                    </tr>
                </slot>
                <tr>
                    <td colspan="4" class="text-center">{{ $t('globalTrans.total') }}</td>
                    <td class="text-center">{{ getFirstItemAmount(applicationData) }}</td>
                    <td class="text-center">{{ getSecondItemAmount(applicationData) }}</td>
                    <td class="text-center">{{ getThirdItemAmount(applicationData) }}</td>
                    <td class="text-center">{{ getFourthItemAmount(applicationData) }}</td>
                    <td class="text-center">{{ getFifthItemAmount(applicationData) }}</td>
                    <td class="text-right"></td>
                </tr>
            </table>
        </b-col>
    </b-row>
</template>
<script>
import licenseRegistrationServiceBaseUrl from '@/config/api_config'
import reportPdf from '../pdf/pdf_c'
export default {
    props: ['applicationData'],
    data () {
        return {
            fileData: {
                attachment: '',
                applicationData: '',
                type: 2
            }
        }
    },
    created () {
    },
    computed: {
        local: function () {
            return this.$i18n.locale
        }
    },
    methods: {
        getFirstItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update_1.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 2) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getSecondItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update_1.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 3) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getThirdItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update_1.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 4) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getFourthItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update_1.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 5) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getFifthItemAmount (item) {
            let total = 0
            item.forEach(data => {
                data.add_more_update_1.forEach(dataItem => {
                    Object.values(dataItem).forEach((itm, index) => {
                        if (index === 6) {
                            if (itm) {
                                total = total + parseInt(itm)
                            } else {
                                total = total + 0
                            }
                        }
                    })
                })
            })
            return this.$n(total)
        },
        getMiddleData (item) {
            return Object.values(item).filter((itm, index) => index !== 7)
        },
        getEndData (item) {
            return item.map(item => {
                return item.Comments
            })
        },
        getIndexToKey (item, myIndex) {
            const middleKeys = Object.keys(item).filter((itm, index) => index !== 7)
            return middleKeys[myIndex]
        },
        pdfExport () {
            const reportTitle = this.$t('externalUserIrrigation.money_receipt_title')
            reportPdf.exportPdfDetails(licenseRegistrationServiceBaseUrl, 0, reportTitle, [], this.applicationData, this)
        }
    }
  }
</script>
